.strategyAndAlternatives {
    float: left;
    overflow: auto;
    width: 100%;
    position: relative;
}

.strategyAndAlternatives table thead tr th {
    width: 200px;
    background: #01305F;
    word-break: break-word;
    color: #ffffff;
    padding: 30px 15px;
}

.strategyAndAlternatives table thead tr th, .strategyAndAlternatives table tbody tr td {
    border-left: 1px solid rgba(164, 164, 164, 0.3);

}

.strategyAndAlternatives table thead tr th:nth-child(1), .strategyAndAlternatives table tbody tr td:nth-child(1) {
    border-left: 0;
    text-align: center;
    width: 140px;
    background: #EEF2F5;

}
.strategyAndAlternatives table tbody tr td:nth-child(1){
    padding: 90px 0;
}
.strategyAndAlternatives table thead tr th:nth-child(1) {
    background: #01305F;
}

.strategyAndAlternatives table thead tr th:nth-child(2) {
    color: #ffffff;
}

.strategyAndAlternatives table tbody tr th:nth-child(1) {
    word-break: break-word;
    background: #F3F6FF !important;
    min-width: 150px;
    white-space: pre;
}

.strategyAndAlternatives table tbody tr td {
    color: rgba(0, 0, 0, 0.9);
    font-size: 16px;
    font-weight: 500;
    background: #F7F9FB;
    vertical-align: top;
}

.strategyAndAlternatives table tbody tr td button {
    float: left;
}

.strategyAndAlternatives table tbody tr td ul li div {
    margin: 0;
    float: left;
}

.strategyAndAlternatives table tbody tr td ul li {
    display: inline-block;
    float: left;
    width: 100%;
    height: auto;
    font-weight: normal;
}

.tableRow {
    visibility: visible !important;
    opacity: 1 !important;
}

.tableRow:hover {
    cursor: all-scroll;
    background: #e8e7ec;
}
